
  import { defineComponent,computed } from 'vue'
  import useDealerBreakdownReport from "@/hooks/payment/EntitySiPayout/useDealerBreakdownReport";
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  import TableWrapper from "@/components/TableWrapper.vue";
  import ViewModal from "@/views/Payment/EntitySiPayout/DealerBreakdownReport/viewModal.vue";
  import CommentModal from '@/views/Payment/components/commentModal.vue'
  import { useRoute } from "vue-router";
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  import {
    FileExcelOutlined} from '@ant-design/icons-vue';
  import * as API from '@/API/payment';
  import {ref, nextTick} from 'vue'
  import { message, Modal } from 'ant-design-vue';
  import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';



  type Key = ColumnProps['key'];
  const tableWidth = window.innerWidth
  const tableHeight = window.innerHeight-482



  
  export default defineComponent({
    name: 'VehicleNameSet',
    components: {
      TableWrapper,
      ViewModal,
      CommentModal,
      ExclamationCircleOutlined,
      FileExcelOutlined
    },
    props: {},
    setup() {
      const {
        state, payoutRoundList, regionList, statusList, getInitSelect,
        columnsData, tableData, rowSelection, doSearch, doReset, pageChange, sizeChange, pageSize, pageNumber, total,
        downloadClick, releaseClick, withdrawClick, viewBatchDownload,
        modalVisible, modalClose, modalTitle, fileColumns, fileList, fileRowSelection, confirmClick, btnName, loading,
        viewVisible, openView, fileId, dealerDetailApproveId, getTableData,
        commentVisible, commentData, openComment, submissionList, submissionDeadline, disabledSubmissionDeadline,
        releaseConfirmModalVisible, handleCloseReleaseConfirmModal, handleRelease, exportExcel
      } = useDealerBreakdownReport()
      const route = useRoute()
      // 获取Entity下拉列表
      getInitSelect();
      const handlePageChange = (page: number,pageSize: number) =>{
        pageChange(page, pageSize)
      }
      const handleSizeChange = (page: number,pageSize: number) =>{
        sizeChange(page, pageSize)
      }
      const handleDropdownVisibleChange = () => {
        API.getSubmissionByRound(state.payoutRound!).then(data => {
          submissionList.value = data
        });
      }

      const setBatchPlaceholder = () => {
        if (textNoArray.value.length) {
          batchPlaceholder.value = [];
        } else {
          batchPlaceholder.value = ['', '', '', '', '', ''];
        }
      };
      const batchVinModalVisible = ref(false);
      const textNoArray = ref<string[]>([]);
      const textContentNo = ref<string>('');
      const input = ref();
      const batchPlaceholder = ref(['', '', '', '', '', '']);
      const textChangEvent = () => {
        if (textContentNo.value.length < 5) {
          message.error('请从Excel中粘贴到文本框');
          textContentNo.value = '';
          return;
        }
        textNoArray.value = textNoArray.value.concat(
          textContentNo.value
            .replace(/\r|\n|\s/g, ',')
            .split(',')
            .filter((item) => item)
        );
        const dom = document.getElementsByClassName('list-content')[0];
        textContentNo.value = '';
        state.dealerCodes = [];
        console.log('textNoArray.value'+textNoArray.value);
        state.dealerCodes = state.dealerCodes.concat(textNoArray.value);
        console.log('state.dealerCode'+state.dealerCode);
        setBatchPlaceholder();
        nextTick(() => {
          dom.scrollTo(0, dom.scrollHeight + 40);
          input.value.focus();
        });
      };

      const setBatchQueryTextNoArray = () => {
          textNoArray.value =
            cleanEmptyEleArray(state.dealerCodes).length === 0 ? [] : cleanEmptyEleArray(state.dealerCodes);
      };

      const clearBatchQuery = () => {
        state.dealerCodes = [];
        setBatchQueryTextNoArray();
        setBatchPlaceholder();
        input.value.focus();
      };

      // 批量查询按钮事件
      const batchQueryEvent = (arg: string) => {
        batchVinModalVisible.value = true;
        setBatchQueryTextNoArray();
        setBatchPlaceholder();
        const dom = document.getElementsByClassName('list-content')[0];
        nextTick(() => {
          dom && dom.scrollTo(0, textNoArray.value.length * 36);
          input.value.focus();
        });
      };

      return {
        route,
        state,
        payoutRoundList,
        regionList,
        statusList,
        doSearch,
        doReset,
        tableWidth,
        tableHeight,
        columnsData,
        tableData,
        rowSelection,
        pageSize,
        pageNumber,
        total,
        handlePageChange,
        handleSizeChange,
        downloadClick,
        releaseClick,
        withdrawClick,
        viewBatchDownload,
        modalVisible,
        modalClose,
        modalTitle,
        fileColumns,
        fileList,
        fileRowSelection,
        confirmClick,
        btnName,
        loading,
        viewVisible,
        openView,
        fileId,
        dealerDetailApproveId,
        getTableData,
        commentVisible,
        commentData,
        openComment,
        submissionList,
        submissionDeadline,
        disabledSubmissionDeadline,
        releaseConfirmModalVisible,
        handleCloseReleaseConfirmModal,
        handleRelease,
        FileExcelOutlined,
        exportExcel,
        handleDropdownVisibleChange,
        batchVinModalVisible,
        textNoArray,
        textContentNo,
        input,
        batchPlaceholder,
        textChangEvent,
        clearBatchQuery,
        batchQueryEvent,
        setBatchQueryTextNoArray
      }
    }
  })
  